import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { setLang, setLoading } from '../reducers/systemSlice';

import { STRINGS } from '../constants/strings';
import '../styles/gulu-logo.scss';
import { LANG } from '../constants/constants';

export const GuluLogo = ({ show, showLang = false }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.system.lang);

  function handleLangClick() {
    dispatch(setLoading(true));
    switch(lang) {
      case LANG.TC:
        moment.locale('en');
        dispatch(setLang(LANG.EN));
        break;
      default:
        moment.locale('zh-hk');
        dispatch(setLang(LANG.TC));
    }
  }

  return (
    <div className="gulu-logo-wrapper">
      {
        show &&
        <div className="image-wrapper">
          <div className="gulu-logo">
            <>
              <img className="image" src={process.env.PUBLIC_URL + "/images/gulu-logo.png"} alt="thegulu" />
              <img className="image image-bottom logo-blink" src={process.env.PUBLIC_URL + "/images/gulu-logo-reverse.png"} alt="thegulu" />
            </>
          </div>
        </div>
      }
      {
        showLang &&
        <div className="lang-wrapper">
          <div className="lang-button" onClick={handleLangClick}>{STRINGS[lang].langButton}</div>
        </div>
      }
    </div>
  )
};
