import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GuluLogo } from '../components/GuluLogo';

import '../styles/success-email-sent.scss';
import { STRINGS } from '../constants/strings';

const QueueRequestTicketSuccess = () => {
  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);

  useEffect(() => {
    document.title = STRINGS[lang].queueRequestTicketSuccess;
  }, [lang]);

  return (
    <div className="queue-wrapper">
      {
        queueConfig &&
        <>
          <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
          <div className="success-email-sent-wrapper">
            <div className="success">{STRINGS[lang].success}</div>
            <div className="sent">
              <span>{STRINGS[lang].sent}</span>
              <span className="email">{requestParams.personalData.email}</span>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default QueueRequestTicketSuccess;
