// import { STATUS } from '../constants/general';
// import { STRINGS } from '../constants/strings';

export const processQuery = (queryString) => {
  const params = {};
  let temp;
  let i;
  let l;
  const queries = queryString.split('&');
  for (i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split('=');
    params[temp[0]] = temp[1];
  }
  return params;
}

export const isAndroid = () => {
  if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
    return true;
  }
  return false;
}

export const isIOS = () => {
  if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)) {
    return true;
  }
  return false;
}

// export const getStatusText = (status, lang) => {
//   console.log(status);
//   switch (status) {
//     case STATUS.ACTIVE:
//       return STRINGS[lang].active;
//     case STATUS.PENDING:
//       return STRINGS[lang].pending;
//     case STATUS.PAYMENT_FAIL:
//     case STATUS.FAIL:
//       return STRINGS[lang].fail;
//     default:
//       return '';
//   }
// }

export const tableColor = (tableType) => {
  switch (tableType) {
    case 'B':
    case 'H':
    case 'N':
    case 'T':
    case 'Z':
      return 'B'
    case 'C':
    case 'I':
    case 'O':
    case 'U':
      return 'C'
    case 'D':
    case 'J':
    case 'P':
    case 'V':
      return 'D'
    case 'E':
    case 'K':
    case 'Q':
    case 'W':
      return 'E'
    case 'F':
    case 'L':
    case 'R':
    case 'X':
      return 'F'
    default:
      return 'A'
  };
}

export const validateEmail = (email) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)(\.[a-zA-Z0-9-]+){0,1}$/i.test(email);
}

export const validateMobile = (mobile) => {
  return /^[0-9]+$/i.test(mobile);
}