import { createSlice } from '@reduxjs/toolkit';
import { LANG } from '../constants/constants';

export const systemSlice = createSlice({
  name: 'system',
  initialState: {
    lang: LANG.TC,
    loading: false,
    message: '',
    token: '',
    mqttTopicList: []
  },
  reducers: {
    setLang(state, action) {
      state.lang = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    subscribeMqttTopic(state, action) {
      state.mqttTopicList.push(action.payload);
    },
    unsubscribeMqttTopic(state, action) {
      state.mqttTopicList = state.mqttTopicList.filter((topic) => { return topic !== action.payload });
    }
  }
})

export const { setLang, setLoading, setMessage, setToken, subscribeMqttTopic, unsubscribeMqttTopic } = systemSlice.actions

export default systemSlice.reducer