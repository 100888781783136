import React from 'react';
import '../styles/site-info.scss';

export const SiteInfo = ({ site }) => {
  return (
    <div className="site-wrapper">
      {
        site &&
        <>
          <img className="site-image" src={site.imageUrl} alt="thegulu" />
          <div className="info">
            <div className="title">{site.name}</div>
            <div className="address">
              <img className="image" src={process.env.PUBLIC_URL + "/images/address-icon.png"} alt="address" />
              <div className="text">{site.location}</div>
            </div>
          </div>
        </>
      }
    </div>
  )
};
