import { setLoading, setMessage } from '../reducers/systemSlice';
import { STRINGS } from '../constants/strings';
import { LANG } from '../constants/constants';
import { API_URLS } from '../constants/apiUrls';

// export const get = (path, data, successCallback, failCallback, dispatch, lang) => {
//   dispatch(setLoading(true));

//   const apiDomain = `${process.env.REACT_APP_API_ROOT_URL}`;
//   const queryParams = new URLSearchParams(data).toString();
// const headers = {
//   'Content-Type': 'application/json;charset=UTF-8',
//   'Accept-Language': lang ? lang : LANG.TC
// };
// if (token) {
//   headers['X-AUTH-TOKEN'] = token;
// }

//   fetch(apiDomain + path + '?' + queryParams, {
//     method: 'GET',
//     headers: headers,
//   }).then((res) => {
//     return res.json()
//   }).then((data) => {
//     // console.log("data", data);
//     if (data.returnCode === 0) {
//       successCallback(data.payload);
//     } else if (data.returnCode > 0) {
//       if (failCallback) {
//         failCallback(data.message);
//       } else {
//         dispatch(setMessage(data.message));
//       }
//     } else {
//       dispatch(setMessage(STRINGS[lang].serverError));
//     }
//   }).finally(() => {
//     dispatch(setLoading(false));
//   });
// }

export const getOne = (path, data, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().system.lang;
  const token = store.getState().system.token;
  dispatch(setLoading(true));

  const apiDomain = `${process.env.REACT_APP_API_ROOT_URL}`;

  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept-Language': lang ? lang : LANG.TC
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  fetch(apiDomain + path + data, {
    method: 'GET',
    headers: headers,
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    // console.log("data", data);
    if (data.returnCode === 0) {
      successCallback(data.payload);
    } else if (data.returnCode > 0) {
      if (failCallback) {
        failCallback(data.message);
      } else {
        dispatch(setMessage(data.message));
      }
    } else {
      dispatch(setMessage(STRINGS[lang].serverError));
    }
  }).catch((error) => {
    console.log('error', error);
  }).finally(() => {
    dispatch(setLoading(false));
  });
}

export const post = (path, data, successCallback, failCallback, store) => {
  const dispatch = store.dispatch;
  const lang = store.getState().system.lang;
  const token = store.getState().system.token;
  dispatch(setLoading(true));

  const apiDomain = `${process.env.REACT_APP_API_ROOT_URL}`;

  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept-Language': lang ? lang : LANG.TC
  };
  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  fetch(apiDomain + path, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    } else {
      return Promise.reject('status code !== 200');
    }
  }).then((data) => {
    // console.log("data", data);
    if (data.returnCode === 0) {
      if (path === API_URLS.QUEUE && data.payload && data.payload.ticketRejectReason) {
        dispatch(setMessage(data.payload.ticketRejectReason));
      } else {
        successCallback(data.payload);
      }
    } else if (data.returnCode > 0) {
      if (failCallback) {
        failCallback(data.message);
      } else {
        dispatch(setMessage(data.message));
      }
    } else {
      dispatch(setMessage(STRINGS[lang].serverError));
    }
  }).catch((error) => {
    console.log('error', error);
  }).finally(() => {
    dispatch(setLoading(false));
  });
}