import React from 'react';
import '../styles/queue-title-info.scss';

export const QueueTitle = ({title}) => {

  return (
    <>
      <div className="queue-title">{title}</div>
      <div className="queue-title-underline" />
    </>
  )
};
