import { createSlice } from '@reduxjs/toolkit'

export const siteSlice = createSlice({
  name: 'site',
  initialState: {
    site: undefined
  },
  reducers: {
    setSite(state, action) {
      state.site = action.payload;
    }
  }
})

export const { setSite } = siteSlice.actions

export default siteSlice.reducer