import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { STRINGS } from '../constants/strings';
import { QUEUE_STEPS } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-special-request.scss';

const QueueSpecialRequest = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);

  const [specialRequestList, setSpecialRequestList] = useState([]);

  useEffect(() => {
    document.title = STRINGS[lang].queueSpecialRequest;
  }, [lang]);

  useEffect(() => {
    if (!queueConfig || (requestParams.selectedDate && !queueConfig.filterResult)) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(API_URLS.QUEUE, newParams, (payload) => {
        const newConfig = mergeQueueConfig(queueConfig, payload);
        dispatch(setQueueConfig(newConfig));
      }, undefined, store);
    }
  }, [params.siteId, dispatch, store, queueConfig]);

  useEffect(() => {
    if (queueConfig && queueConfig.selectTags) {
      setSpecialRequestList(queueConfig.selectTags);
    }
  }, [queueConfig])


  function handleSpecialRequestChange(specialRequest) {
    const newParams = _.cloneDeep(requestParams);
    // newParams.tableType = serviceType.tableType;
    dispatch(setRequestParams(newParams));
  }

  return (
    <div className="queue-wrapper">
      {
        queueConfig &&
        <>
          <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
          <div className="background-site-info"><SiteInfo site={queueConfig && queueConfig.siteInfo} /></div>
          <div className="queue-special-request-wrapper">
            <QueueTitle title={STRINGS[lang].selectSpecialRequest} />
            {
              specialRequestList.map((specialRequest, index) => {
                return <div className="special-request-checkbox-wrapper" key={index}>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id={specialRequest}
                      name={specialRequest}
                      onChange={() => handleSpecialRequestChange(specialRequest)}
                    />
                  </div>
                  <label htmlFor={specialRequest}>{specialRequest}</label>
                </div>
              })
            }
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.TAGS} defaultValid />
        </>
      }
    </div>
  );
}

export default QueueSpecialRequest;
