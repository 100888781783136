import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { STRINGS } from '../constants/strings';
import { QUEUE_STEPS } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-service-type.scss';

const QueueServiceType = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);

  const [serviceTypeList, setServiceTypeList] = useState([]);

  useEffect(() => {
    document.title = STRINGS[lang].queueServiceType;
  }, [lang]);

  useEffect(() => {
    if (!queueConfig || (requestParams.selectedDate && !queueConfig.filterResult)) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(API_URLS.QUEUE, newParams, (payload) => {
        const newConfig = mergeQueueConfig(queueConfig, payload);
        dispatch(setQueueConfig(newConfig));
      }, undefined, store);
    }
  }, [params.siteId, dispatch, store, queueConfig]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const avaliableSectionList = getAvaliableSectionList(queueConfig.steps, QUEUE_STEPS.TABLE_TYPE, requestParams, queueConfig.filterResult);
      const tempMap = new Map();
      avaliableSectionList.forEach((section) => {
        if (!tempMap.has(section.tableType)) {
          tempMap.set(section.tableType, {
            tableType: section.tableType,
            tableTypeLabel: section.tableTypeLabel
          });
        }
      });
      setServiceTypeList(Array.from(tempMap.values()));
    }
  }, [queueConfig, requestParams])

  function handleServiceTypeChange(serviceType) {
    const newParams = _.cloneDeep(requestParams);
    newParams.tableType = serviceType.tableType;
    dispatch(setRequestParams(newParams));
  }

  return (
    <div className="queue-wrapper">
      {
        queueConfig &&
        <>
          <GuluLogo show={queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
          <div className="background-site-info"><SiteInfo site={queueConfig && queueConfig.siteInfo} /></div>
          <div className="queue-service-type-wrapper">
            <QueueTitle title={STRINGS[lang].selectServiceType} />
            {
              serviceTypeList.map((serviceType, index) => {
                return <div className="service-type-radio-wrapper" key={index}>
                  <input
                    type="radio"
                    id={serviceType.tableType}
                    name="serviceType"
                    checked={serviceType.tableType === requestParams.tableType}
                    onChange={() => handleServiceTypeChange(serviceType)}
                  />
                  <img className="image" src={process.env.PUBLIC_URL + "/images/service-type.png"} alt="thegulu" />
                  <label htmlFor={serviceType.tableType}>{serviceType.tableTypeLabel}</label>
                </div>
              })
            }
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.TABLE_TYPE} />
        </>
      }
    </div>
  );
}

export default QueueServiceType;
