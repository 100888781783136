import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { setRequestTicketSuccessfully } from '../reducers/queueSlice';
import { getQueuePageByStep } from '../utils/queueUtils';
import { PATH } from '../constants/paths';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { validateEmail, validateMobile } from '../utils/utils';

import '../styles/queue-bottom-btn.scss';
import { QUEUE_STEPS, PERSONAL_DATAS, DELIVERY_TYPE } from '../constants/constants';
import { setMessage } from '../reducers/systemSlice';
import { STRINGS } from '../constants/strings';

export const QueueBottomBtn = ({ currentStep, defaultValid = undefined }) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useStore();
  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const requestTicketSuccessfully = useSelector((state) => state.queue.requestTicketSuccessfully);
  const token = useSelector((state) => state.system.token);
  const lang = useSelector((state) => state.system.lang);

  const [stepIndex, setStepIndex] = useState(-1);
  const [valid, setValid] = useState((defaultValid !== undefined) ? defaultValid : false);

  useEffect(() => {
    const index = queueConfig.steps.findIndex((step) => { return step === currentStep });
    setStepIndex(index);
  }, [queueConfig.steps, currentStep])

  useEffect(() => {
    let tempValid = false;
    switch (currentStep) {
      case QUEUE_STEPS.DATE:
        if (requestParams.selectedDate) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.SIZE:
        if (requestParams.size) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.TABLE_TYPE:
        if (requestParams.tableType) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.TIME_SECTION:
        if (requestParams.timeSectionId) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.PERSONAL_DATA:
        tempValid = queueConfig.personalDataRequired.every((personalData) => {
          switch (personalData.type) {
            case PERSONAL_DATAS.EMAIL:
              return !personalData.required || (personalData.required && requestParams.personalData.email && validateEmail(requestParams.personalData.email));
            case PERSONAL_DATAS.MOBILE:
              return !personalData.required || (personalData.required && requestParams.personalData.mobile && validateMobile(requestParams.personalData.mobile) && requestParams.personalData.countryCode);
            default:
              return true;
          }
        })
        break;
      case QUEUE_STEPS.TICKET_TYPE:
        if (requestParams.ticketType) {
          tempValid = true;
        }
        break;
      case QUEUE_STEPS.PREVIEW:
        tempValid = requestParams.personalData.tncAgreed && requestParams.ticketTncAgreed
        break;
      default:
    }
    setValid(tempValid);
  }, [requestParams, currentStep, queueConfig.personalDataRequired])

  function handlePreviousClick() {
    history.goBack();
  }

  function handleNextClick() {
    if (valid) {
      if (stepIndex !== -1 && stepIndex < queueConfig.steps.length - 1) {
        const nextStep = queueConfig.steps[stepIndex + 1];
        const newParams = _.cloneDeep(requestParams);
        newParams.nextStep = nextStep;
        history.push(getQueuePageByStep(nextStep) + params.siteId);
      } else if (stepIndex !== -1 && stepIndex === queueConfig.steps.length - 1) {
        if (requestTicketSuccessfully) {
          switch (queueConfig.deliveryType) {
            case DELIVERY_TYPE.EMAIL:
              dispatch(setMessage(STRINGS[lang].requestedTicket.replace('%deliveryType%', STRINGS[lang].email.toLowerCase())));
              break;
            case DELIVERY_TYPE.SMS:
              dispatch(setMessage(STRINGS[lang].requestedTicket.replace('%deliveryType%', STRINGS[lang].sms)));
              break;
            default:
            // history.push(PATH.TICKET + payload.id);
          }
        } else {
          const newParams = _.cloneDeep(requestParams);
          newParams.siteId = params.siteId;
          const sectionList = getAvaliableSectionList(queueConfig.steps, undefined, newParams, queueConfig.filterResult);
          if (sectionList && sectionList.length === 1) {
            if (!newParams.size) {
              newParams.size = sectionList[0].minSize;
            }
            if (!newParams.tableType) {
              newParams.tableType = sectionList[0].tableType;
            }
            if (!newParams.timeSectionId) {
              newParams.timeSectionId = sectionList[0].timeSectionId;
            }
            if (!newParams.ticketType && sectionList[0].ticketType !== null) {
              newParams.ticketType = sectionList[0].ticketType;
            }
            newParams.timeSectionUUId = sectionList[0].id;
            post(API_URLS.REQUEST_TICKET, newParams, (payload) => {
              dispatch(setRequestTicketSuccessfully(true));
              if (token) {
                window.location = "thegulu://myticket?serviceType=QUEUE&refId=" + payload.id;
              } else {
                switch (queueConfig.deliveryType) {
                  case DELIVERY_TYPE.EMAIL:
                  case DELIVERY_TYPE.SMS:
                    history.push(PATH.QUEUE_REQUEST_TICKET_SUCCESS);
                    break;
                  default:
                    history.push(PATH.TICKET + payload.id);
                }
              }
            }, undefined, store);
          }
        }
      }
    }
  }

  return (
    <div className="queue-bottom-btn-wrapper">
      {
        stepIndex > 0 &&
        <div onClick={handlePreviousClick} className="queue-button previous">&#8592;</div>
      }
      <div onClick={handleNextClick} className={"queue-button next" + ((!valid) ? " disable" : "")}>&#8594;</div>
    </div>
  )
};
