import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { LANG } from '../constants/constants';

import '../styles/calendar.scss';

const Calendar = ({ startDate, endDate, calendarDisplayDate, disableDateList, selectedDate, handleDateClick, handleMonthClick }) => {

  const lang = useSelector((state) => state.system.lang);

  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    switch (lang) {
      case LANG.EN:
        moment.updateLocale('en', {
          weekdaysMin: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
        });
        break;
      default:
    }
  }, [lang]);

  useEffect(() => {
    const daysInMonth = calendarDisplayDate.daysInMonth();
    const startingWeekOfDay = calendarDisplayDate.set('date', 1).day();
    let tempWeeks = [];
    let week = [];
    const daysInWeek = 6;
    for (let i = 1; i <= daysInMonth;) {
      for (let j = 0; j <= daysInWeek; j++) {
        if (i === 1 && startingWeekOfDay !== j) {
          week.push(<td key={calendarDisplayDate.format('MM') + (-1 * j)}></td>);
          continue;
        } else {
          week.push(generateDateCell(i));
        }
        i++;
        if (i > daysInMonth) {
          if (j <= daysInWeek) {
            for (let k = 0; k < (daysInWeek - j); k++) {
              week.push(<td key={calendarDisplayDate.format('MM') + i + k}></td>);
            }
          }
          break;
        }
      }
      tempWeeks.push(<tr key={'tr-' + i}>{week.slice()}</tr>);
      week = [];
    }
    setWeeks(tempWeeks);
  }, [startDate, endDate, calendarDisplayDate, selectedDate]);

  function generateDateCell(date) {
    const month = moment(calendarDisplayDate).month();
    const momentDate = moment(calendarDisplayDate).set({ 'date': date });
    const selected = selectedDate && moment(selectedDate).isSame(momentDate, 'day');
    const disable = momentDate.isBefore(moment(startDate)) || momentDate.isAfter(moment(endDate)) || (disableDateList && disableDateList.some((disableDate) => moment(disableDate).isSame(momentDate, 'day')));
    return (
      <td key={month + date}>
        <div className={'date-wrapper' + ((selected) ? ' selected' : '') + ((disable) ? ' disable' : '')}
          onClick={() => {
            if (!disable) {
              handleDateClick(momentDate.format("YYYY-MM-DD"));
            }
          }}>
          {date}
        </div>
      </td>
    )
  }

  return (
    <div className="calendar">
      <div className="header">
        <div className="button-wrapper">
          {
            (!startDate || (startDate && moment(calendarDisplayDate).subtract(1, 'month').isSameOrAfter(moment(startDate), 'month'))) &&
            <div className="prev-month" onClick={() => { handleMonthClick(calendarDisplayDate.subtract(1, 'month').valueOf()); }}>{"<"}</div>
          }
        </div>
        <div className="title">{calendarDisplayDate.format('MMM YYYY')}</div>
        <div className="button-wrapper">
          {
            (!endDate || (endDate && moment(calendarDisplayDate).add(1, 'month').isSameOrBefore(moment(endDate), 'month'))) &&
            <div className="next-month" onClick={() => { handleMonthClick(calendarDisplayDate.add(1, 'month').valueOf()); }}>{">"}</div>
          }
        </div>
      </div>
      <div className="weekdays-background" />
      <table className="calendar-content">
        <thead>
          <tr className="weekdays">
            <td>{moment.weekdaysMin(0)}</td>
            <td>{moment.weekdaysMin(1)}</td>
            <td>{moment.weekdaysMin(2)}</td>
            <td>{moment.weekdaysMin(3)}</td>
            <td>{moment.weekdaysMin(4)}</td>
            <td>{moment.weekdaysMin(5)}</td>
            <td>{moment.weekdaysMin(6)}</td>
          </tr>
        </thead>
        <tbody>
          {weeks}
        </tbody>
      </table>
    </div>
  )
};

export default Calendar;