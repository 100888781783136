import _ from 'lodash';
import { QUEUE_STEPS } from '../constants/constants';
import { PATH } from '../constants/paths';

export const getAvaliableSectionList = (steps, currentStep, requestParams, sectionList = []) => {
  let avaliableSectionList = _.cloneDeep(sectionList);

  if (!steps || steps.length === 0 || !requestParams || !sectionList || sectionList.length === 0) {
    return [];
  }

  const index = steps.findIndex((step) => { return step === currentStep });
  const previousSteps = _.slice(steps, 0, index);
  previousSteps.forEach((step) => {
    switch (step) {
      case QUEUE_STEPS.TIME_SECTION:
        if (requestParams.timeSectionId) {
          avaliableSectionList = avaliableSectionList.filter((section) => {
            return section.timeSectionId === requestParams.timeSectionId;
          });
        }
        break;
      case QUEUE_STEPS.TABLE_TYPE:
        if (requestParams.tableType) {
          avaliableSectionList = avaliableSectionList.filter((section) => {
            return section.tableType === requestParams.tableType;
          });
        }
        break;
      case QUEUE_STEPS.TICKET_TYPE:
        if (requestParams.ticketType) {
          avaliableSectionList = avaliableSectionList.filter((section) => {
            return section.ticketType === requestParams.ticketType;
          });
        }
        break;
      case QUEUE_STEPS.SIZE:
        if (requestParams.size) {
          avaliableSectionList = avaliableSectionList.filter((section) => {
            return requestParams.size >= section.minSize && requestParams.size <= section.maxSize;
          });
        }
        break;
      default:
    }
  })

  return (avaliableSectionList) ? avaliableSectionList : [];
}

export const getQueuePageByStep = (step) => {
  switch (step) {
    case QUEUE_STEPS.CURRENT_SECTION:
      return PATH.QUEUE_CURRENT_SECTION;
    case QUEUE_STEPS.SIZE:
      return PATH.QUEUE_SIZE;
    case QUEUE_STEPS.TABLE_TYPE:
      return PATH.QUEUE_SERVICE_TYPE;
    case QUEUE_STEPS.DATE:
      return PATH.QUEUE_DATE;
    case QUEUE_STEPS.TIME_SECTION:
      return PATH.QUEUE_TIME_SECTION;
    case QUEUE_STEPS.PERSONAL_DATA:
      return PATH.QUEUE_PERSONAL_INFO;
    case QUEUE_STEPS.PREVIEW:
      return PATH.QUEUE_PREVIEW;
    case QUEUE_STEPS.TICKET_TYPE:
      return PATH.QUEUE_TICKET_TYPE;
    default:
      return ''
  }
}

export const mergeQueueConfig = (queueConfig, payload) => {
  let newConfig = _.cloneDeep(queueConfig);
  if (!queueConfig) {
    newConfig = payload;
  } else {
    if (!newConfig.currentSection) {
      newConfig.currentSection = payload.currentSection;
    }
    if (!newConfig.filterResult) {
      newConfig.filterResult = payload.filterResult;
    }
    if (!newConfig.personalDataRequired) {
      newConfig.personalDataRequired = payload.personalDataRequired;
    }
    if (!newConfig.selectDate) {
      newConfig.selectDate = payload.selectDate;
    }
    if (!newConfig.filterResult) {
      newConfig.filterResult = payload.filterResult;
    }
    if (!newConfig.selectTags) {
      newConfig.selectTags = payload.selectTags;
    }
    if (!newConfig.steps) {
      newConfig.steps = payload.steps;
    }
    if (!newConfig.termsAndConditions) {
      newConfig.termsAndConditions = payload.termsAndConditions;
    }
    if (!newConfig.ticketRejectReason) {
      newConfig.ticketRejectReason = payload.ticketRejectReason;
    }
    newConfig.siteInfo = payload.siteInfo;
    newConfig.queueAvailable = payload.queueAvailable;
  }
  return newConfig;
}