import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GuluLogo } from '../components/GuluLogo';

import { STRINGS } from '../constants/strings';

import '../styles/error.scss';

const Error = () => {
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);

  useEffect(() => {
    document.title = STRINGS[lang].invalidUrl;
  }, [lang]);

  return (
    <div className="queue-wrapper">
      <GuluLogo show={!token} />
      <div className="message">{STRINGS[lang].invalidUrl}</div>
    </div>
  );
}

export default Error;