export const LANG = {
  TC: "TC",
  EN: "EN"
}

export const MQTT_MSG_TYPE = {
  PICKUP_ADD: 'PICKUP_ADD',
  PICKUP_REMOVE: 'PICKUP_REMOVE',
  TOPIC_ALIVE: 'TOPIC_ALIVE'
}
export const MQTT_RESET_TIME = 10000;

export const QUEUE_STEPS = {
  CURRENT_SECTION: 'CURRENT_SECTION',
  SIZE: 'SIZE',
  TIME_SECTION: 'TIME_SECTION',
  TICKET_TYPE: 'TICKET_TYPE',
  TABLE_TYPE: 'TABLE_TYPE',
  DATE: 'DATE',
  TAGS: 'TAGS',
  PERSONAL_DATA: 'PERSONAL_DATA',
  PREVIEW: 'PREVIEW'
}

export const PERSONAL_DATAS = {
  EMAIL: 'EMAIL',
  MOBILE: 'MOBILE',
  COUNTRY_CODE: 'COUNTRY_CODE',
  TAC: 'TAC'
}

export const TICKET_DETAIL = {
  TABLE_SIZE: 'tableSize',
  TIME_SECTION: 'timeSectionLabel',
  TICKET_TYPE: 'ticketTypeLabel',
  TABLE_TYPE: 'tableTypeLabel',
  DATE: 'startTimestamp',
  TAGS: 'selectTagList',
  PERSONAL_DATA: 'PERSONAL_DATA',
}

export const DELIVERY_TYPE = {
  EMAIL: 'EMAIL',
  SMS: 'SMS'
}

export const STATUS = {
  ACTIVE: 'A'
}