export const PATH = {
  LANDING: '/',
  RESTAURANT_LIST: '/restaurant_list/',
  SITE_DETAIL: '/site_detail/',
  QUEUE_CURRENT_SECTION: '/queue_current_section/',
  QUEUE_SIZE: '/queue_size/',
  QUEUE_TICKET_TYPE: '/queue_ticket_type/',
  QUEUE_TIME_SECTION: '/queue_time_section/',
  QUEUE_SERVICE_TYPE: '/queue_service_type/',
  QUEUE_DATE: '/queue_date/',
  QUEUE_TAGS: '/queue_tags/',
  QUEUE_PERSONAL_INFO: '/queue_personal_info/',
  QUEUE_PREVIEW: '/queue_preview/',
  QUEUE_REQUEST_TICKET_SUCCESS: '/queue_success/',
  PICKUP: '/pickup/',
  TICKET: '/ticket/',
  ERROR: '/error'
}