import React, { useState } from 'react';
import '../styles/drop-down.scss';

export const DropDown = ({ prefixTitle, titleClass, title, contentClass, content, open = true }) => {
  const [tncClick, setTncClick] = useState(open);

  return (
    <>
      <div className={"drop-down-wrapper " + ((titleClass) ? titleClass : "")}>
        {prefixTitle}
        <div className="drop-down-title-wrapper" onClick={() => setTncClick(!tncClick)}>
          <div>{title}</div>
          <div className="dropdown-arrow"><img className={"image" + ((tncClick) ? "" : " change")} src={process.env.PUBLIC_URL + "/images/drop-down.png"} alt="drop-down" /></div>
        </div>
      </div>
      <div className={`tnc ${(tncClick) ? (contentClass)? contentClass: "" : "display-none"}`} dangerouslySetInnerHTML={{ __html: content }}></div>
    </>
  )
};
