import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { STRINGS } from '../constants/strings';
import { setMessage } from '../reducers/systemSlice';

import '../styles/dialog.scss';

export const Dialog = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.system.lang);
  const message = useSelector((state) => state.system.message);

  function handleDialogClose() {
    dispatch(setMessage(''));
  }

  return (
    <>
      {
        message &&
        <div className="dialog-wrapper">
          <div className="dialog">
            <div className="dialog-text">{message}</div>
            <div className="dialog-line"></div>
            <div className="dialog-button" onClick={handleDialogClose}>{STRINGS[lang].ok}</div>
          </div>
        </div>
      }
    </>
  )
};
