import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { STRINGS } from '../constants/strings';
import { QUEUE_STEPS, STATUS } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-time-section.scss';

const QueueTimeSection = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);

  const [timeSectionList, setTimeSectionList] = useState([]);

  useEffect(() => {
    document.title = STRINGS[lang].queueTimeSection;
  }, [lang]);

  useEffect(() => {
    if (!queueConfig || (requestParams.selectedDate && !queueConfig.filterResult)) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(API_URLS.QUEUE, newParams, (payload) => {
        const newConfig = mergeQueueConfig(queueConfig, payload);
        dispatch(setQueueConfig(newConfig));
      }, undefined, store);
    }
  }, [params.siteId, dispatch, store, queueConfig]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const avaliableSectionList = getAvaliableSectionList(queueConfig.steps, QUEUE_STEPS.TIME_SECTION, requestParams, queueConfig.filterResult);
      const tempMap = new Map();
      avaliableSectionList.forEach((section) => {
        if (!tempMap.has(section.timeSectionId)) {
          tempMap.set(section.timeSectionId, {
            timeSectionId: section.timeSectionId,
            timeSectionLabel: section.timeSectionLabel,
            status: section.status
          });
        }
      });
      setTimeSectionList(Array.from(tempMap.values()));
    }
  }, [queueConfig, requestParams])

  function handleTimeSection(timeSectionId, status) {
    if (status === STATUS.ACTIVE) {
      const newParams = _.cloneDeep(requestParams);
      newParams.timeSectionId = timeSectionId;
      dispatch(setRequestParams(newParams));
    }
  }

  return (
    <div className="queue-wrapper">
      {
        queueConfig &&
        <>
          <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
          <div className="background-site-info"><SiteInfo site={queueConfig && queueConfig.siteInfo} /></div>
          <div className="queue-time-section-wrapper">
            <QueueTitle title={STRINGS[lang].selectTimeSection} />
            {
              timeSectionList.map((timeSection, index) => {
                const disabled = timeSection.status !== STATUS.ACTIVE;
                return <div key={index} className={"time-section-radio-wrapper" + ((disabled)? ' disable': '')}
                  onClick={() => handleTimeSection(timeSection.timeSectionId, timeSection.status)}>
                  <input
                    type="radio"
                    id={timeSection.timeSectionId}
                    name="timeSectionId"
                    checked={timeSection.timeSectionId === requestParams.timeSectionId}
                    readOnly
                    disabled={disabled}
                  />
                  <div>{timeSection.timeSectionLabel}</div>
                  {
                    disabled &&
                    <div className="disabled-label">{STRINGS[lang].disabledLabel}</div>
                  }
                </div>
              })
            }
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.TIME_SECTION} />
        </>
      }
    </div >
  );
}

export default QueueTimeSection;
