import { createSlice } from '@reduxjs/toolkit'

import { QUEUE_REQUEST_PARAMS } from '../constants/initialStates';

export const queueSlice = createSlice({
  name: 'queue',
  initialState: {
    queueConfig: undefined,
    requestParams: QUEUE_REQUEST_PARAMS,
    requestTicketSuccessfully: false
  },
  reducers: {
    setQueueConfig(state, action) {
      state.queueConfig = action.payload;
    },
    setRequestParams(state, action) {
      state.requestParams = action.payload;
    },
    setRequestTicketSuccessfully(state, action) {
      state.requestTicketSuccessfully = action.payload;
    },
    resetQueue(state, action) {
      state.queueConfig = action.payload.queueConfig;
      state.requestParams = action.payload.requestParams;
    }
  }
})

export const { setQueueConfig, setRequestParams, setRequestTicketSuccessfully, resetQueue } = queueSlice.actions

export default queueSlice.reducer