export const QUEUE_REQUEST_PARAMS = {
  siteId: '',
  size: 0,
  tableType: '',
  selectedDate: '',
  timeSectionId: '',
  ticketType: '',
  tags: [],
  personalData: {
    email: '',
    countryCode: '852',
    mobile: '',
    tncAgreed: false
  },
  ticketTncAgreed: false,
  nextStep: ''
}