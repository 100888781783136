import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { STRINGS } from '../constants/strings';
import { QUEUE_STEPS } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-ticket-type.scss';

const QueueTicketType = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);
  
  const [ticketTypeList, setTicketTypeList] = useState([]);

  useEffect(() => {
    document.title = STRINGS[lang].queueTicketType;
  }, [lang]);

  useEffect(() => {
    if (!queueConfig || (requestParams.selectedDate && !queueConfig.filterResult)) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(API_URLS.QUEUE, newParams, (payload) => {
        const newConfig = mergeQueueConfig(queueConfig, payload);
        dispatch(setQueueConfig(newConfig));
      }, undefined, store);
    }
  }, [params.siteId, dispatch, store, queueConfig]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const avaliableSectionList = getAvaliableSectionList(queueConfig.steps, QUEUE_STEPS.TICKET_TYPE, requestParams, queueConfig.filterResult);
      const tempMap = new Map();
      avaliableSectionList.forEach((section) => {
        if (!tempMap.has(section.ticketType)) {
          tempMap.set(section.ticketType, {
            ticketType: section.ticketType,
            ticketTypeLabel: section.ticketTypeLabel
          });
        }

      });
      setTicketTypeList(Array.from(tempMap.values()));
    }
  }, [queueConfig, requestParams])

  function handleTicketType(ticketType) {
    const newParams = _.cloneDeep(requestParams);
    newParams.ticketType = ticketType;
    dispatch(setRequestParams(newParams));
  }

  return (
    <div className="queue-wrapper">
      {
        queueConfig &&
        <>
          <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
          <div className="background-site-info"><SiteInfo site={queueConfig && queueConfig.siteInfo} /></div>
          <div className="queue-ticket-type-wrapper">
            <QueueTitle title={STRINGS[lang].selectTicketType} />
            {
              ticketTypeList.map((ticketType, index) => {
                return <div key={index} className="ticket-type-radio-wrapper"
                  onClick={() => handleTicketType(ticketType.ticketType)}>
                  <input
                    type="radio"
                    id={ticketType.ticketType}
                    name="ticketType"
                    checked={ticketType.ticketType === requestParams.ticketType}
                  />
                  <div>{ticketType.ticketTypeLabel}</div>
                </div>
              })
            }
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.TICKET_TYPE} />
        </>
      }
    </div >
  );
}

export default QueueTicketType;
