import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';

import { API_URLS } from '../constants/apiUrls';
import { STRINGS } from '../constants/strings';
import { post } from '../utils/baseFetch';
import { setSite } from '../reducers/siteSlice';
import { setQueueConfig, setRequestParams, setRequestTicketSuccessfully } from '../reducers/queueSlice';
import { getQueuePageByStep } from '../utils/queueUtils';
import { QUEUE_REQUEST_PARAMS } from '../constants/initialStates';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueTitle } from '../components/QueueTitle';
import { DropDown } from '../components/DropDown';

import '../styles/site-detail.scss';

const Restaurant = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const site = useSelector((state) => state.site.site);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);

  useEffect(() => {
    document.title = STRINGS[lang].siteTitle;
  }, [lang]);

  useEffect(() => {
    const newParams = { siteId: params.siteId };
    post(API_URLS.SITE, newParams, (payload) => {
      if (payload.title) {
        document.title = payload.title;
      }
      dispatch(setQueueConfig(undefined));
      dispatch(setSite(payload));
    }, undefined, store);
  }, [params.siteId, dispatch, store, lang]);

  function handleQueueClick() {
    if (site.firstStep) {
      dispatch(setRequestParams(QUEUE_REQUEST_PARAMS));
      dispatch(setRequestTicketSuccessfully(false));
      history.push(getQueuePageByStep(site.firstStep) + params.siteId);
    }
  }

  return (
    <div className="site-detail-wrapper">
      {
        site &&
        <>
          <GuluLogo show={!token} showLang={!token} />
          <div className="background-site-info"><SiteInfo site={site} /></div>
          <QueueTitle title={site.title} />
          <div className="banner-list">
            {
              site.bannerList && site.bannerList.map((banner, index) => {
                return (
                  <img key={index} className="detail-image" src={banner} alt="banner" />
                )
              })
            }
          </div>
          <div className="queue-title-underline" />
          <div className="detail-wrapper">
            {
              site.descriptionList && site.descriptionList.map((description, index) => {
                return (
                  <DropDown
                    key={index}
                    titleClass="ticket-title"
                    title={description.TITLE}
                    content={description.CONTENT}
                  />
                )
              })
            }
          </div>
          <div className={"queue-button" + ((!site.firstStep) ? " disable" : "")} onClick={handleQueueClick}>{STRINGS[lang].requestTicket}</div>
        </>
      }
    </div>
  );
}

export default Restaurant;