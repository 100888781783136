import React from 'react';

const RestaurantList = () => {
  return (
    <div className="restaurant-list-wrapper">
      Restaurant List Page
    </div>
  );
}

export default RestaurantList;