export const STRINGS = {
  TC: {
    // page titles
    pickupNumber: "取餐號碼",
    queueTableSize: "請選擇人數",
    queueTimeSection: "請選擇時段",
    queueDate: "請選擇日期",
    queuePreview: "確認資料",
    queuePersonalInfo: "填寫個人資料",
    queueServiceType: "請選擇服務",
    queueTicketType: "請選擇類型",
    queueSpecialRequest: "請選擇特別安排",
    queueRequestTicketSuccess: "取票成功",
    siteTitle: "活動",
    ticketTitle: "票號詳情",
    // others
    requestTicket: "立即取票",
    serverError: "未能完成請求",
    selectTableSize: "請選擇人數",
    selectTimeSection: "請選擇時段",
    selectServiceType: "請選擇服務",
    selectTicketType: "請選擇類型",
    selectDate: "請選擇日期",
    selectSpecialRequest: "請選擇特別安排",
    selectPersonalInfo: "填寫個人資料",
    selectPreview: "資料",
    tableSize: "人數",
    tableType: "服務",
    timeSection: "時段",
    date: "日期",
    ticketType: "類型",
    tags: "特別安排",
    noTag: "沒選擇",
    personalData: "個人資料",
    agreeTnc: "同意條款及細則",
    tnc: "條款及細則",
    emailTitle: "電郵地址*",
    email: "電郵地址",
    emailError: "*您輸入的電郵地址格式錯誤！",
    phoneTitle: "手機號碼*",
    phone: "手機號碼",
    phoneError: "*您輸入的手機號碼格式錯誤！",
    personalInfoTnc: "同意透過個人資料接收優惠並作推廣用途。",
    invalidUrl: "沒有此頁面",
    success: "您已取票成功! ",
    sent: "確認資料已發至 ",
    ok: "確定",
    langButton: "中文/ENG",
    requestedTicket: "已經成功取票，請查看%deliveryType%",
    sms: "短訊",
    disabledLabel: "滿",
    redeemed: "已使用",
  },
  EN: {
    // page titles
    pickupNumber: "Pickup number",
    queueTableSize: "Select number of people",
    queueTimeSection: "Select time section",
    queueDate: "Select date",
    queuePreview: "Confirmation",
    queuePersonalInfo: "Fill in personal info",
    queueServiceType: "Select service",
    queueTicketType: "Select type",
    queueSpecialRequest: "Select special request",
    queueRequestTicketSuccess: "Get ticket success",
    siteTitle: "Event",
    ticketTitle: "Ticket",
    // others
    requestTicket: "Get Ticket",
    serverError: "Server error",
    selectTableSize: "Select Number of People",
    selectTimeSection: "Select Time Section",
    selectServiceType: "Select Service",
    selectDate: "Select Date",
    selectSpecialRequest: "Select Special Request",
    selectPersonalInfo: "Fill in Personal Info",
    selectPreview: "Confirmation",
    tableSize: "Number of People",
    tableType: "Service",
    timeSection: "Time Section",
    date: "Date",
    ticketType: "Type",
    tags: "Special Request",
    noTag: "N/A",
    personalData: "Personal Info",
    agreeTnc: "Agree the terms & conditions",
    tnc: "Terms & Conditions",
    emailTitle: "Email*",
    email: "Email",
    emailError: "You have entered an invalid email!",
    phoneTitle: "Mobile number*",
    phone: "Mobile number",
    phoneError: "You have entered an invalid mobile number!",
    personalInfoTnc: "Agree to receive promotion via the personal info",
    invalidUrl: "Page not found",
    success: "Get ticket successfully! ",
    sent: "Confirmation is sent to ",
    ok: "OK",
    langButton: "中文/ENG",
    requestedTicket: "You have got ticket successfully. Please check %deliveryType%",
    sms: "SMS",
    disabledLabel: "Full",
    redeemed: "Checked in",
  }
}