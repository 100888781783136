import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { STRINGS } from '../constants/strings';
import { validateEmail, validateMobile } from '../utils/utils';
import { PERSONAL_DATAS, QUEUE_STEPS } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-personal-info.scss';

const QueuePersonalInfo = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [personalDataList, setPersonalDataList] = useState([]);

  useEffect(() => {
    document.title = STRINGS[lang].queuePersonalInfo;
  }, [lang]);

  useEffect(() => {
    if (!queueConfig || (requestParams.selectedDate && !queueConfig.filterResult)) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(API_URLS.QUEUE, newParams, (payload) => {
        const newConfig = mergeQueueConfig(queueConfig, payload);
        dispatch(setQueueConfig(newConfig));
      }, undefined, store);
    }
  }, [params.siteId, dispatch, store]);

  useEffect(() => {
    if (queueConfig && queueConfig.personalDataRequired) {
      const tempPersonalDataList = [];
      queueConfig.personalDataRequired.forEach((item, index) => {
        if (item.type === PERSONAL_DATAS.EMAIL && item.required) {
          tempPersonalDataList.push(
            <div className="email-wrapper" key={index}>
              <input
                className={`email ${emailError ? "error" : ""}`}
                onChange={(item) => { handlePersonalInfoChange(item, PERSONAL_DATAS.EMAIL) }}
                type="text"
                id="email"
                name="email"
                placeholder={STRINGS[lang].emailTitle}
                value={requestParams.personalData.email}
              />
              <div className="error-message email-error-message">{emailError}</div>
            </div>
          )
        } else if (item.type === PERSONAL_DATAS.MOBILE && item.required) {
          tempPersonalDataList.push(
            <div className="phone-content" key={index}>
              <div className={`phone-wrapper ${phoneError ? "error" : ""}`}>
                <select value={requestParams.personalData.countryCode} onChange={(item) => { handlePersonalInfoChange(item, PERSONAL_DATAS.COUNTRY_CODE) }} className="country-code-select" id="countryCode" name="countryCode">
                  <option value="852">+852</option>
                  <option value="853">+853</option>
                  <option value="86">+86</option>
                </select>
                <img className="image" src={process.env.PUBLIC_URL + "/images/drop-down.png"} alt="drop-down" />
                <input value={requestParams.personalData.mobile} type="text" onChange={(item) => handlePersonalInfoChange(item, PERSONAL_DATAS.MOBILE)} className="phone-input" id="phone" name="phone" placeholder={STRINGS[lang].phoneTitle} />
              </div>
              <div className="error-message phone-error-message">{phoneError}</div>
            </div>
          )
        }
      })
      setPersonalDataList(tempPersonalDataList);
    }
  }, [queueConfig, requestParams, lang, emailError, phoneError])

  function handlePersonalInfoChange(personalInfo, type) {
    const newParams = _.cloneDeep(requestParams);
    switch (type) {
      case PERSONAL_DATAS.EMAIL:
        newParams.personalData.email = personalInfo.target.value;
        setEmailError(((validateEmail(personalInfo.target.value)) ? "" : STRINGS[lang].emailError));
        break;
      case PERSONAL_DATAS.COUNTRY_CODE:
        newParams.personalData.countryCode = personalInfo.target.value;
        break;
      case PERSONAL_DATAS.MOBILE:
        newParams.personalData.mobile = personalInfo.target.value;
        setPhoneError(((validateMobile(personalInfo.target.value)) ? "" : STRINGS[lang].phoneError));
        break;
      case PERSONAL_DATAS.TAC:
        newParams.personalData.tncAgreed = !newParams.personalData.tncAgreed;
        break;
      default:
    }
    dispatch(setRequestParams(newParams));
  }

  return (
    <div className="queue-wrapper">
      {
        queueConfig &&
        <>
          <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
          <div className="background-site-info"><SiteInfo site={queueConfig && queueConfig.siteInfo} /></div>
          <div className="queue-personal-info-wrapper">
            <QueueTitle title={STRINGS[lang].selectPersonalInfo} />
            <div className="personal-info-wrapper">
              {personalDataList}
            </div>
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.PERSONAL_DATA} />
        </>
      }
    </div>
  );
}

export default QueuePersonalInfo;
