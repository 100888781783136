import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment';
import QRCode from "qrcode.react";

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { STRINGS } from '../constants/strings';
import { setTicket } from '../reducers/ticketSlice';
import { GuluLogo } from '../components/GuluLogo';
import { tableColor } from '../utils/utils';
import { SiteInfo } from '../components/SiteInfo';
import { DropDown } from '../components/DropDown';
import { STATUS } from '../constants/constants';

import { ReactComponent as ReloadLogo } from '../svg/reload.svg';
import { ReactComponent as SoundLogo } from '../svg/sound.svg';

import '../styles/ticket.scss';

const Ticket = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  // const history = useHistory();
  const ticket = useSelector((state) => state.ticket.ticket);
  const token = useSelector((state) => state.system.token);
  const lang = useSelector((state) => state.system.lang);

  const [color, setColor] = useState('A');

  const [displayList, setDisplayList] = useState([])

  useEffect(() => {
    document.title = STRINGS[lang].ticketTitle;
  }, [lang]);

  useEffect(() => {
    const newParams = { ticketId: params.ticketId };
    post(API_URLS.TICKET, newParams, (payload) => {
      dispatch(setTicket(payload));
    }, undefined, store);
    // }
  }, [params.ticketId, dispatch, store, lang]);

  useEffect(() => {
    if (ticket && ticket.tableType) {
      setColor(tableColor(ticket.tableType));
    }
  }, [ticket])

  useEffect(() => {
    if (ticket) {
      const tempDisplayList = [];
      // if (ticket.size && ticket.sizeSelectable) {
      //   displayList.push(<div key="tableSize">
      //     <div className="ticket-title">{STRINGS[lang].tableSize}</div>
      //     <div className="ticket-text">{ticket.size}</div>
      //     <div className="middle-line"></div>
      //   </div>)
      // }
      if (ticket.tableTypeLabel) {
        tempDisplayList.push(<div key="tableType">
          <div className="ticket-title">{STRINGS[lang].tableType}</div>
          <div className="ticket-text">{ticket.tableTypeLabel}</div>
          <div className="middle-line"></div>
        </div>)
      }
      if (ticket.startTimestamp) {
        tempDisplayList.push(<div key="date">
          <div className="ticket-title">{STRINGS[lang].date}</div>
          <div className="ticket-text">{moment(ticket.startTimestamp).format("YYYY-MM-DD")}</div>
          <div className="middle-line"></div>
        </div>)
      }
      if (ticket.timeSectionLabel) {
        tempDisplayList.push(<div key="timeSection">
          <div className="ticket-title">{STRINGS[lang].timeSection}</div>
          <div className="ticket-text">{ticket.timeSectionLabel}</div>
          <div className="middle-line"></div>
        </div>)
      }
      if (ticket.ticketTypeLabel) {
        tempDisplayList.push(<div key="ticketType">
          <div className="ticket-title">{STRINGS[lang].ticketType}</div>
          <div className="ticket-text">{ticket.ticketTypeLabel}</div>
          <div className="middle-line"></div>
        </div>)
      }
      if (ticket.tags && ticket.tags.length > 0) {
        tempDisplayList.push(<div key="tags">
          <div className="ticket-title">{STRINGS[lang].tags}</div>
          <div className="ticket-text">
            <div>{ticket.tags.length > 0 ? ticket.tags.forEach((item) => { tempDisplayList.push(<div>{item}</div>) }) : STRINGS[lang].noTag}</div>
          </div>
          <div className="middle-line"></div>
        </div>)
      }
      setDisplayList(tempDisplayList);
    }

  }, [lang, ticket]);

  return (
    <div className="ticket-wrapper">
      {
        ticket &&
        <>
          <GuluLogo show={!token} showLang />
          {
            ticket.tagSequence &&
            <div className={`tv-box text-${color} background-dark-${color}`}>
              <div className="ticket-number">
                <div className="text"><SoundLogo className={`sound-icon logo-${color}`} />{ticket.tagSequence.tagLabel}</div>
                <ReloadLogo className={`reload-icon logo-${color}`} />
              </div>
              <div className={`zig-zag zig-zag-${color}`}></div>
              <div className="time">{'@ ' + ((ticket.tagSequence.updateTimestamp) ? moment(ticket.tagSequence.updateTimestamp).format('HH:mmA') : '----')}</div>
            </div>
          }
          <div className="ticket-box">
            <div className={`zig-zag zig-zag-top zig-zag-${color}`}></div>
            <div className={`ticket-box-content text-${color} background-dark-${color}`}>
              <div className={`ticket-content background-dark-${color}`}>
                <SiteInfo site={ticket && ticket.siteInfo} />
                <div className={`ticket-number background-${color}`}>
                  {ticket.ticketLabel}
                  {
                    ticket.size && ticket.sizeSelectable &&
                    <div className="table-size">
                      <img className="image" src={process.env.PUBLIC_URL + "/images/people.png"} alt="people" />
                      <div>{ticket.size}</div>
                    </div>
                  }
                </div>
                <div className="qrcode-wrapper">
                  {ticket.status === STATUS.ACTIVE &&
                    <div className="redeemed">{STRINGS[lang].redeemed}</div>
                  }
                  <QRCode id="qrCode" size={140} value={ticket.qrcode} level={'L'} renderAs={"svg"} />
                </div>
                <div className="ticket-detail-wrapper">
                  <div className="middle-line"></div>
                  <div className="ticket-detail">
                    {displayList}
                  </div>
                  <DropDown
                    titleClass="ticket-title"
                    title={STRINGS[lang].tnc}
                    content={ticket.termsAndConditions}
                  />
                </div>
              </div>
            </div>
            <div className={`zig-zag zig-zag-bottom zig-zag-${color}`}></div>
          </div>
        </>
      }
    </div>
  );
}

export default Ticket;