import React, { useEffect, useState } from 'react';
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { tableColor } from '../utils/utils';
import { STRINGS } from '../constants/strings';
import { QUEUE_STEPS } from '../constants/constants';
// import { API_URLS } from '../constants/apiUrls';
// import { post } from '../utils/baseFetch';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';
import { PreviewEditBtn } from '../components/PreviewEditBtn';
import { DropDown } from '../components/DropDown';

import '../styles/queue-preview.scss';

const QueuePreview = () => {
  const dispatch = useDispatch();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);

  const [avaliableSectionList, setAvaliableSectionList] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    document.title = STRINGS[lang].queuePreview;
  }, [lang]);

  useEffect(() => {
    if (queueConfig && queueConfig.personalDataRequired) {
      const tempAvaliableSectionList = getAvaliableSectionList(queueConfig.steps, QUEUE_STEPS.PREVIEW, requestParams, queueConfig.filterResult);
      if (tempAvaliableSectionList && tempAvaliableSectionList.length === 1) {
        setAvaliableSectionList(tempAvaliableSectionList[0]);
        setReady(true);
      } else {
        console.log('more than one avaliable section');
      }
    }
  }, [queueConfig]);

  function handleTncClick() {
    const newParams = _.cloneDeep(requestParams);
    newParams.ticketTncAgreed = !newParams.ticketTncAgreed;
    dispatch(setRequestParams(newParams));
  }

  function handlePersonalInfoChange() {
    const newParams = _.cloneDeep(requestParams);
    newParams.personalData.tncAgreed = !newParams.personalData.tncAgreed;
    dispatch(setRequestParams(newParams));
  }

  return (
    ready &&
    <div className="queue-wrapper">
      <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
      <div className="background-site-info">
        <SiteInfo site={queueConfig && queueConfig.siteInfo} />
      </div>
      <div className="queue-preview-wrapper">
        <QueueTitle title={STRINGS[lang].selectPreview} />
        <div className="queue-preview-content">
          {queueConfig.steps.map((preview, index) => {
            let content = <></>;
            switch (preview) {
              case QUEUE_STEPS.SIZE:
                content = <div key={index}>
                  <div className="preview-title">{STRINGS[lang].tableSize}</div>
                  <div className="preview-text">
                    <div className="table-name">
                      {/* <div className={`text-${tableColor(avaliableSectionList.tableType)} background-dark-${tableColor(avaliableSectionList.tableType)} background`}>{avaliableSectionList.tableTypeLabel ? '' : avaliableSectionList.tableType}</div> */}
                      <div className={`text-${tableColor(avaliableSectionList.tableType)}`}>{requestParams.size}</div>
                    </div>
                    <PreviewEditBtn editStep={preview} />
                  </div>
                </div>
                break;
              case QUEUE_STEPS.TABLE_TYPE:
                content = <div key={index}>
                  <div className="preview-title">{STRINGS[lang].tableType}</div>
                  <div className="preview-text">
                    <div className="table-name">
                      <div className={`text-${avaliableSectionList.tableType}`}>{avaliableSectionList.tableTypeLabel ? avaliableSectionList.tableTypeLabel : avaliableSectionList.tableType}</div>
                    </div>
                    <PreviewEditBtn editStep={preview} />
                  </div>
                </div>
                break;
              case QUEUE_STEPS.DATE:
                content = <div key={index}>
                  <div className="preview-title">{STRINGS[lang].date}</div>
                  <div className="preview-text">{requestParams.selectedDate}
                    <PreviewEditBtn editStep={preview} />
                  </div>
                </div>
                break;
              case QUEUE_STEPS.TIME_SECTION:
                content = <div key={index}>
                  <div className="preview-title">{STRINGS[lang].timeSection}</div>
                  <div className="preview-text">{avaliableSectionList.timeSectionLabel}
                    <PreviewEditBtn editStep={preview} />
                  </div>
                </div>
                break;
              case QUEUE_STEPS.TICKET_TYPE:
                content = <div key={index}>
                  <div className="preview-title">{STRINGS[lang].ticketType}</div>
                  <div className="preview-text">{requestParams.ticketType}
                    <PreviewEditBtn editStep={preview} />
                  </div>
                </div>
                break;
              case QUEUE_STEPS.TAGS:
                content = <div key={index}>
                  <div className="preview-title">{STRINGS[lang].tags}</div>
                  <div className="preview-text">
                    <div>{requestParams.tags.length > 0 ? requestParams.tags.forEach((item) => { return <div>{item}</div> }) : STRINGS[lang].noTag}</div>
                    <PreviewEditBtn editStep={preview} />
                  </div>
                </div>
                break;
              case QUEUE_STEPS.PERSONAL_DATA:
                content = <div key={index}>
                  <div className="preview-title">{STRINGS[lang].personalData}</div>
                  <div className="personal-data-wrapper">
                    <div className="personal-data-contant">
                      {
                        requestParams.personalData.email &&
                        <div className="preview-text">{requestParams.personalData.email}</div>
                      }
                      {
                        requestParams.personalData.mobile && requestParams.personalData.countryCode &&
                        <div className="personal-data-phone">
                          <div className="preview-text">{'+' + requestParams.personalData.countryCode}</div>
                          <div className="preview-text phone">{requestParams.personalData.mobile}</div>
                        </div>
                      }
                    </div>
                    <PreviewEditBtn editStep={preview} />
                  </div>
                </div>
                break;
              default:
            }
            return content;
          })
          }
          <DropDown
            open={false}
            prefixTitle={
              <div className="checkbox-wrapper">
                <div className="checkbox" onClick={handleTncClick}>
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={requestParams.ticketTncAgreed}
                  />
                </div>
              </div>
            }
            title={STRINGS[lang].agreeTnc}
            content={queueConfig.termsAndConditions}
          />
          <div className="checkbox-wrapper personal-data" onClick={handlePersonalInfoChange}>
            <div className="checkbox">
              <input
                type="checkbox"
                id="checkbox"
                name="personal-data-tnc"
                checked={requestParams.personalData.tncAgreed}
              />
            </div>
            <div>{STRINGS[lang].personalInfoTnc}</div>
          </div>
        </div>
      </div>
      <QueueBottomBtn currentStep={QUEUE_STEPS.PREVIEW} />
    </div>
  );
}

export default QueuePreview;
