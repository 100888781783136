import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Main from './containers/Main';
import RestaurantList from './containers/RestaurantList';
import SiteDetail from './containers/SiteDetail';
import Pickup from './containers/Pickup';
import Queue from './containers/Queue';
import QueueTableSize from './containers/QueueTableSize';
import QueueTicketType from './containers/QueueTicketType';
import QueueTimeSection from './containers/QueueTimeSection';
import QueueServiceType from './containers/QueueServiceType';
import QueueDate from './containers/QueueDate';
import QueueSpecialRequest from './containers/QueueSpecialRequest';
import QueuePersonalInfo from './containers/QueuePersonalInfo';
import QueuePreview from './containers/QueuePreview';
import Ticket from './containers/Ticket';
import QueueRequestTicketSuccess from './containers/QueueRequestTicketSuccess';
import Error from './containers/Error';

import { PATH } from './constants/paths';

export default (
  <Switch>
    <Route exact path={PATH.LANDING} component={Main} />
    <Route exact path={PATH.RESTAURANT_LIST} component={RestaurantList} />
    <Route exact path={PATH.SITE_DETAIL + ':siteId'} component={SiteDetail} />
    <Route exact path={PATH.PICKUP + ':restUrlId'} component={Pickup} />

    <Route exact path={PATH.QUEUE_CURRENT_SECTION + ':siteId'} component={Queue} />
    <Route exact path={PATH.QUEUE_SIZE + ':siteId'} component={QueueTableSize} />
    <Route exact path={PATH.QUEUE_TICKET_TYPE + ':siteId'} component={QueueTicketType} />
    <Route exact path={PATH.QUEUE_TIME_SECTION + ':siteId'} component={QueueTimeSection} />
    <Route exact path={PATH.QUEUE_SERVICE_TYPE + ':siteId'} component={QueueServiceType} />
    <Route exact path={PATH.QUEUE_DATE + ':siteId'} component={QueueDate} />
    <Route exact path={PATH.QUEUE_TAGS + ':siteId'} component={QueueSpecialRequest} />
    <Route exact path={PATH.QUEUE_PERSONAL_INFO + ':siteId'} component={QueuePersonalInfo} />
    <Route exact path={PATH.QUEUE_PREVIEW + ':siteId'} component={QueuePreview} />
    <Route exact path={PATH.TICKET + ':ticketId'} component={Ticket} />
    <Route exact path={PATH.QUEUE_REQUEST_TICKET_SUCCESS} component={QueueRequestTicketSuccess} />

    <Route exact path={PATH.ERROR} component={Error} />
    <Route exact path={'*'}> <Redirect to={PATH.ERROR} /></Route>
  </Switch>
)
