import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { setQueueConfig, setRequestParams } from '../reducers/queueSlice';
import { getAvaliableSectionList } from '../utils/queueUtils';
import { STRINGS } from '../constants/strings';
import { QUEUE_STEPS } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';

import '../styles/queue-table-size.scss';

const QueueTableSize = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);

  const [tableSizeList, setTableSizeList] = useState([]);

  useEffect(() => {
    document.title = STRINGS[lang].queueTableSize;
  }, [lang]);

  useEffect(() => {
    if (!queueConfig || (requestParams.selectedDate && !queueConfig.filterResult)) {
      const newParams = _.cloneDeep(requestParams);
      newParams.siteId = params.siteId;
      post(API_URLS.QUEUE, newParams, (payload) => {
        const newConfig = mergeQueueConfig(queueConfig, payload);
        dispatch(setQueueConfig(newConfig));
      }, undefined, store);
    }
  }, [params.siteId, dispatch, store, queueConfig]);

  useEffect(() => {
    if (queueConfig && queueConfig.filterResult) {
      const avaliableSectionList = getAvaliableSectionList(queueConfig.steps, QUEUE_STEPS.SIZE, requestParams, queueConfig.filterResult);
      const tempSet = new Set();
      avaliableSectionList.forEach((section) => {
        for (let i = section.minSize; i <= section.maxSize; i++) {
          tempSet.add(i);
        }
      });
      setTableSizeList(Array.from(tempSet).sort((a, b) => a - b));
    }
  }, [queueConfig, requestParams])

  function handleTableSizeClick(tableSize) {
    const newParams = _.cloneDeep(requestParams);
    newParams.size = tableSize;
    dispatch(setRequestParams(newParams));
  }

  return (
    <div className="queue-wrapper">
      {
        queueConfig &&
        <>
          <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
          <div className="background-site-info"><SiteInfo site={queueConfig && queueConfig.siteInfo} /></div>
          <div className="queue-table-size-wrapper">
            <QueueTitle title={STRINGS[lang].selectTableSize} />
            <div className="number-btn-wrapper" >
              {
                tableSizeList.map((item, index) => {
                  return <div key={index} className={"table-size-btn" + ((item === requestParams.size) ? " selected" : "")} onClick={() => handleTableSizeClick(item)}>{item}</div>
                })
              }
            </div>
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.SIZE} />
        </>
      }
    </div>
  );
}

export default QueueTableSize;
