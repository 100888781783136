import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { API_URLS } from '../constants/apiUrls';
import { post } from '../utils/baseFetch';
import { mergeQueueConfig } from '../utils/queueUtils';
import { usePrevious } from '../utils/hooks'
import { setQueueConfig, resetQueue } from '../reducers/queueSlice';
import { STRINGS } from '../constants/strings';
import { QUEUE_STEPS } from '../constants/constants';

import { SiteInfo } from '../components/SiteInfo';
import { GuluLogo } from '../components/GuluLogo';
import { QueueBottomBtn } from '../components/QueueBottomBtn';
import { QueueTitle } from '../components/QueueTitle';
import Calendar from '../components/Calendar';

import '../styles/queue-date.scss';

const QueueDate = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  const queueConfig = useSelector((state) => state.queue.queueConfig);
  const requestParams = useSelector((state) => state.queue.requestParams);
  const selectedDate = useSelector((state) => state.queue.requestParams.selectedDate);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);
  const previousSelectedDate = usePrevious(selectedDate, '');

  const [calendarDisplayDate, setCalendarDisplayDate] = useState(moment().set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }))

  useEffect(() => {
    document.title = STRINGS[lang].queueDate;
  }, [lang]);

  useEffect(() => {
    // if (!requestParams.nextStep) {
    const newParams = _.cloneDeep(requestParams);
    newParams.siteId = params.siteId;
    post(API_URLS.QUEUE, newParams, (payload) => {
      const newConfig = mergeQueueConfig(queueConfig, payload);
      dispatch(setQueueConfig(newConfig));
    }, undefined, store);
    // }
  }, [params.siteId, dispatch, store]);

  useEffect(() => {
    if (queueConfig) {
      let displayDate = moment();
      if (requestParams.selectedDate) {
        displayDate = moment(requestParams.selectedDate);
      } else if (queueConfig.selectDate) {
        displayDate = moment(queueConfig.selectDate);
      }
      setCalendarDisplayDate(displayDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  }, [queueConfig])

  function handleMonthClick(displayDate) {
    setCalendarDisplayDate(moment(displayDate).set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }));
  }

  function handleDateClick(selectedDate) {
    const newQueueConfig = _.cloneDeep(queueConfig);
    const newParams = _.cloneDeep(requestParams);
    if (selectedDate !== previousSelectedDate) {
      newParams.size = 0;
      newParams.tableType = '';
      newParams.timeSectionId = '';
      newParams.ticketType = '';
      newParams.tags = [];
      newParams.ticketTncAgreed = false;
      newQueueConfig.filterResult = undefined;
    }
    newParams.selectedDate = selectedDate;
    dispatch(resetQueue({ queueConfig: newQueueConfig, requestParams: newParams }));
  }

  return (
    <div className="queue-wrapper">
      {
        queueConfig &&
        <>
          <GuluLogo show={!token && queueConfig.siteInfo && queueConfig.siteInfo.showIcon} />
          <div className="background-site-info"><SiteInfo site={queueConfig && queueConfig.siteInfo} /></div>
          <div className="queue-date-wrapper">
            <QueueTitle title={STRINGS[lang].selectDate} />
            <Calendar
              startDate={queueConfig.selectDate && queueConfig.selectDate.startDate}
              endDate={queueConfig.selectDate && queueConfig.selectDate.endDate}
              calendarDisplayDate={calendarDisplayDate}
              disableDateList={queueConfig.selectDate && queueConfig.selectDate.disableDateList}
              selectedDate={requestParams.selectedDate}
              handleDateClick={handleDateClick}
              handleMonthClick={handleMonthClick}
            />
          </div>
          <QueueBottomBtn currentStep={QUEUE_STEPS.DATE} />
        </>
      }
    </div>
  );
}

export default QueueDate;
